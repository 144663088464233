




























import { Component, Mixins, Watch } from "vue-property-decorator";
import { TableQuery } from "@/components/data-table/types";
import Contact, { ContactTypeId } from "@/entity/Contact";
import ContactsService from "@/services/ContactsService";
import { State } from "vuex-class";
import DialogMixin from "@/mixins/DialogMixin";
import Link from "@/components/common/Link.vue";
import companyDetailNavigationTo from "@/utils/companyDetailNavigateTo";
import { ReferenceItem } from "@/services/types";
import ContactFormWrapper from "@/components/ContactFormWrapper.vue";
import CompanyDataTable from "@/components/data-tables/CompanyDataTable.vue";
import CompanyService from "@/services/CompanyService";
import Company from "@/entity/Company";

@Component({
  components: {
    CompanyDataTable,
    ContactFormWrapper,
    Link,
  },
})
export default class CompaniesView extends Mixins(DialogMixin) {
  isTableLoading = false;
  companies: Company[] = [];
  itemsLength = 0;
  tableQuery: TableQuery = {};
  showDisabledCompanies = false;

  contactFormType = ContactTypeId.company;

  @State("industries", { namespace: "selectOptions" })
  industries!: ReferenceItem[];
  @State("subIndustries", { namespace: "selectOptions" })
  subIndustries!: ReferenceItem[];
  @State("regions", { namespace: "selectOptions" }) regions!: ReferenceItem[];
  @State("buyerTypes", { namespace: "selectOptions" })
  buyerTypes!: ReferenceItem[];

  get fullTableQuery() {
    return {
      ...this.tableQuery,
      filterBy: {
        ...this.tableQuery.filterBy,
        contactType: ContactTypeId.company,
        isDisabled: this.showDisabledCompanies ? undefined : false,
      },
    };
  }

  handleOnRowClick(company: Company) {
    this.$router.push({
      name: "companyDetail",
      params: {
        companyId: (company.id as number).toString(),
      },
    });
  }

  async fetchCompanies() {
    try {
      this.isTableLoading = true;
      const response = await CompanyService.find(this.fullTableQuery);
      this.companies = response.content;
      this.itemsLength = response.totalItems;
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToLoad"));
    } finally {
      this.isTableLoading = false;
    }
  }

  handleFormSuccess(company: Company) {
    const to = companyDetailNavigationTo(company);
    if (to) {
      this.$router.push(to);
    }
  }

  onTableQueryChange(tableQuery: TableQuery) {
    this.tableQuery = tableQuery;
  }

  @Watch("fullTableQuery", { deep: true })
  onFullTableQueryChange() {
    this.fetchCompanies();
  }
}
