var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{attrs:{"name":"companiesOverview","loading":_vm.isLoading,"items":_vm.companies,"items-length":_vm.totalItems,"headers":_vm.headers,"clickable":true,"has-add-button":true,"add-button-text":_vm.$tc('newCompany', 1),"itemsPerPageOptions":_vm.itemsPerPageOptions},on:{"rowClicked":_vm.onRowClick,"optionsChanged":_vm.onOptionsChange,"addItemClicked":_vm.addItemClicked},scopedSlots:_vm._u([{key:"top-right",fn:function(){return [_c('v-checkbox',{staticClass:"align-center justify-center",attrs:{"hide-details":"","label":_vm.$t('showAlsoDisabled')},model:{value:(_vm.showDisabledCompaniesLocal),callback:function ($$v) {_vm.showDisabledCompaniesLocal=$$v},expression:"showDisabledCompaniesLocal"}})]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name !== null ? item.name : null)+" "),(item.isDisabled)?_c('v-chip',{staticClass:"text-uppercase ml-2",attrs:{"small":"","text-color":"white","color":"black"},domProps:{"textContent":_vm._s(_vm.$tc('deactivated'))}}):_vm._e()]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.address !== null ? item.address.city : null)+" ")]}},{key:"item.phoneFixed",fn:function(ref){
var item = ref.item;
return [(item.phoneFixed)?_c('Link',{attrs:{"href":item.phoneFixed,"prefix":_vm.LinkType.TELEPHONE}}):_vm._e()]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [(item.url)?_c('Link',{attrs:{"href":item.url}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }