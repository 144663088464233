





















import { Component, Prop, Emit, Vue, Ref } from "vue-property-decorator";
import Contact, { ContactTypeId } from "@/entity/Contact";
import CompanyFormCard from "@/components/company-form/CompanyFormCard.vue";
import ContactFormCard from "@/components/contact-form/ContactFormCard.vue";
import ContactSearchApiForm from "@/components/contact-from-search-api/ContactSearchApiForm.vue";
import { ContactFromApiPayload } from "@/components/contact-from-search-api/types";

@Component({
  components: {
    CompanyFormCard,
    ContactFormCard,
    ContactSearchApiForm,
  },
})
export default class ContactFormWrapper extends Vue {
  @Ref("contact-form") contactForm!: ContactFormCard | CompanyFormCard;

  @Prop({ default: null }) entityId!: null | number;
  @Prop({ required: true, type: Number }) contactTypeId!: ContactTypeId;

  contactTypeIdInternal: ContactTypeId | null = null;
  searchApiDialogOpened = false;

  get componentType() {
    if (this.contactTypeIdInternal === ContactTypeId.contact) {
      return ContactFormCard;
    } else if (this.contactTypeIdInternal === ContactTypeId.company) {
      return CompanyFormCard;
    }

    return null;
  }

  onContactFromApiSelect({ contact, type }: ContactFromApiPayload): void {
    // this.contactTypeIdInternal = type;

    this.$nextTick(() => {
      this.contactForm.setData({
        ...contact,
        // isExternal: type === ContactTypeId.company,
      });
      this.searchApiDialogOpened = false;
    });
  }

  onContactTypeChange(data: any): void {
    this.contactTypeIdInternal = data.type;
  }

  @Emit()
  success(payload: Contact): Contact {
    return payload;
  }

  created() {
    this.contactTypeIdInternal = this.contactTypeId;
  }
}
