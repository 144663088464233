






















































import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import DataTable from "@/components/data-table/DataTable.vue";
import { ITableHeader } from "../data-table/types";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import { ReferenceItem } from "@/services/types";
import { LinkType } from "@/enums";
import Link from "@/components/common/Link.vue";
import Company from "@/entity/Company";

@Component({
  components: {
    DataTable,
    Link,
  },
})
export default class CompanyDataTable extends Mixins(TranslatedEnumListsMixin) {
  @Prop({ type: Array, required: true }) companies!: Company[];
  @Prop({ type: Array, required: true }) regions!: ReferenceItem[];
  @Prop({ type: Array, required: true }) industries!: ReferenceItem[];
  @Prop({ type: Array, required: true }) subIndustries!: ReferenceItem[];
  @Prop({ type: Array, required: true }) buyerTypes!: ReferenceItem[];
  @Prop({ type: Boolean, required: true }) isLoading!: boolean;
  @Prop({ type: Number, required: true }) totalItems!: number;
  @Prop({ type: Function, required: true }) onOptionsChange!: () => void;
  @Prop({ type: Function, required: true }) addItemClicked!: () => void;
  @Prop({ type: Function, required: true }) onRowClick!: () => void;

  @PropSync("showDisabledCompanies", { type: Boolean, required: true })
  showDisabledCompaniesLocal!: boolean;

  LinkType = LinkType;
  itemsPerPageOptions = [5, 10, 15];

  get headers(): ITableHeader[] {
    return [
      {
        filterType: "text",
        text: this.$tc("name", 1),
        value: "name",
        width: "200px",
      },
      {
        filterType: "text",
        text: this.$tc("city", 1),
        value: "city",
        width: "150px",
      },
      {
        filterType: "text",
        text: this.$tc("phone", 1),
        value: "phoneFixed",
        width: "150px",
      },
      {
        filterType: "text",
        text: this.$tc("website", 1),
        value: "url",
        width: "150px",
      },
    ];
  }
}
